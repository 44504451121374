window.__tnt || (window.__tnt = {});
window.__tnt.subscription || (__tnt.subscription = {});
window.__tnt.subscription.d || (__tnt.subscription.d = []);
window.__tnt.subscription.access.manifest || (window.__tnt.subscription.access.manifest = {
    denied: {
        promise: null,
        resolve: function(){ }
    }, 
    granted: {
        promise: null,
        resolve: function(){ }
    }
});

window.tntPurchase = document.createEvent('Event');
    tntPurchase.initEvent('tnt-purchase',true,true,{});

window.tntSignup = document.createEvent('Event');
    tntSignup.initEvent('tnt-signup',true,true,{});

/** 
 * Access Denied - Occlude Content
 */
window.__tnt.subscription.d.push(function(oResp){
    var content = document.getElementById('asset-content'),
        eedition = document.getElementById('eedition-wrapper'),
        modalWrapperEl = document.getElementById('access-offers-modal'),
        wallBehavior = oResp.access_rule.wall_behavior,
        inPagePaywall = null,
        encryptedContent = null,
        subscriberOnly = null;

    if(wallBehavior!="nag"){
        if(eedition){ content = eedition }
        if(content){
            inPagePaywall = content.querySelector('.access-offers-in-page');
            if(modalWrapperEl.hasAttribute('data-offers-in-page')){ inPagePaywall = true }

            if(!inPagePaywall){
                content.hidden = true;
            }
        }

    }else if(content){
    // in nag mode display unencrypted content
        encryptedContent = content.querySelector(".subscriber-only.encrypted-content");
        subscriberOnly = content.querySelectorAll(".subscriber-only,.subscriber-hide");

        if(subscriberOnly && !encryptedContent){
            subscriberOnly.forEach(function(el){ el.style.display = "" });
        }
    }
});

/**
 * Access Denied - Display Access Offers Modal or Gift Wall
 * - falls back to methods if no offers are available
 */
window.__tnt.subscription.d.push(function(oResp){
    const urlParams = new URLSearchParams(document.location.search);
    const loggedInWithServices = __tnt.user.loggedIn && !!__tnt.user.services;

    if (urlParams.has('gift')) {
        if (!loggedInWithServices && urlParams.has('gift_token')) {
            $('#tnt-gift-wall-modal').modal({
                show: true,
                backdrop: 'static',
                keyboard: false
            }).on('hide.bs.modal', function(e) {
                window.location.href = __tnt.scrubUrl('/');
            });
    
            return false;
        }
    }

    var previewMode = false,
        modalWrapperEl = document.getElementById('access-offers-modal'),
        modalEl = null,
        content = document.getElementById('asset-content'),
        offersTab = document.getElementById('modal-offers-tab'),
        offersTitleEl = null,
        offersTitle = "",
        offersDescriptionEl = null,
        offersDescription = "",
        offerGroup = null,
        purchaseTab = document.getElementById('user-modal-tab-purchase'),
        purchaseForm = document.getElementById('form-super-purchase'),
        siteNameEl = document.head.querySelector('meta[property="og:site_name"]'),
        siteName = "",
        outputEl = null,
        templateEl = null,
        limit = 0,
        context = "paywall",
        legacyContext = "Article",
        currency = "USD",
        headerEl = null,
        inPageMode = false;

    if(siteNameEl){ siteName = siteNameEl.content }
    if(modalWrapperEl){
        modalEl = modalWrapperEl.querySelector('.modal');
        if(modalWrapperEl.hasAttribute('data-preview')){ previewMode = true }
        if(modalWrapperEl.hasAttribute('data-offers-in-page')){
            inPageMode = true;
            context = "inline";
        }
    }

    if(!inPageMode){
        __tnt.subscription.access.showTab(offersTab, "access-offers-modal", "access-methods", true);
        $(modalEl).modal('show');
    }

/* Ensure tax storage is clear */
    try{
        sessionStorage.removeItem('__tnt.service.rate.tax');
        sessionStorage.removeItem('__tnt.service.rate.total');
    } catch(e){ console.log(e) }

/* If there are offers, use offers */
    if(!Array.isArray(oResp.offer_config)){
        offerGroup = oResp.offer_config;
        offersTitle = oResp.offer_config.title;
        offersDescription = oResp.offer_config.message;
        
/* if there aren't offers, fall back to access methods */
    }else if(oResp.access_methods){
        var toHide = null,
            toRemove = null;
            
        if(content && modalWrapperEl){
            toHide = content.querySelectorAll('.subscriber-hide,.subscriber-remove,.subscriber-only');
            
            modalWrapperEl.classList.remove('access-offers-wrapper');
            modalWrapperEl.classList.remove('access-offers-modal');
            modalWrapperEl.classList.add('access-methods-modal');
        }

        if(oResp.access_rule && oResp.access_rule.message){
            offersTitle = oResp.access_rule.message.title;
            offersDescription = oResp.access_rule.message.description;
        }

        offerGroup = {
            'id': oResp.access_rule.id,
            'name': offersTitle,
            'offers': [],
            'offers_are_access_methods': true
        };

        oResp.access_methods.forEach(function(method){
            var serviceId = "",
                serviceName = "",
                serviceDescription = "",
                serviceUrl = "",
                serviceImage = null;

            if(method){
                serviceName = method.name;

                if(method.metadata){
                    serviceId = method.metadata.service_id;

                    if(method.metadata.service_info){
                        serviceDescription = method.metadata.service_info.description;
                        serviceUrl = method.metadata.service_info.purchase_url;
                        if(method.metadata.service_info.graphic && method.metadata.service_info.graphic.location){ serviceImage = method.metadata.service_info.graphic.location }
                    }
                }
            }

            if(serviceUrl != ""){
                offerGroup.offers.push({
                    "id": method.id,
                    "service_id": serviceId,
                    "title": serviceName,
                    "description": serviceDescription,
                    "rate": "",
                    "image": serviceImage,
                    "limits": "",
                    "billing_cycle": "",
                    "savings": "",
                    "featured": 0,
                    "featured_message": null,
                    "button_text": "",
                    "link_url": serviceUrl,
                    "link_js": "",
                    "enabled": 1,
                    "start_date": null,
                    "end_date": null,
                    "service_name": serviceName,
                    "rate_name": "",
                    "rate_price": "",
                    "rate_description": "",
                    "rate_currency": ""
                });
            }
        });
    }

// Get output elements
    if(offersTab){
       templateEl = modalEl.querySelector('template.template-promo-service');
       offersTitleEl = offersTab.querySelector('.offer-group-title');
       offersDescriptionEl = document.getElementById('access-modal-description');
       outputEl = document.getElementById('access-methods');
       headerEl = offersTab.querySelector('.offer-group-title');
    }
    
    if(inPageMode){
        var articleBodyEl = document.getElementById('article-body'),
            inPageOffersElTemplate = document.getElementById('access-offers-in-page-template'),
            inPageOffersEl = document.getElementById('access-offers-in-page-container'),
            lastPreviewEl = null;

        if(inPageOffersElTemplate && !inPageOffersEl){
            inPageOffersEl = document.createElement('div');
            inPageOffersEl.id = "access-offers-in-page-container";

            if(articleBodyEl){ lastPreviewEl = articleBodyEl.querySelector('.subscriber-only') }
            __tnt.template(
                inPageOffersElTemplate,     // template element
                inPageOffersEl,       // output element
                false,          // add items from start of output
                [],       // elements in template to modify
                function (t) {    // callback to run after template is added
                    offersTitleEl = t.querySelector('.access-offers-in-page header .offer-group-title');
                    offersDescriptionEl = t.querySelector('.access-offers-in-page header .offer-group-description');
                    outputEl = t.querySelector('.access-methods-list');
                });
        }
    }

// Set Title
    if(offersTitleEl && offersTitle){
        offersTitleEl.innerHTML = offersTitle;
    }
    
// Set Description
    if(offersDescriptionEl && offersDescription){ 
        offersDescriptionEl.innerHTML = offersDescription;
        offersDescriptionEl.classList.add('padding-bottom');
    }else if(offersDescriptionEl){
        offersDescriptionEl.innerHTML = "";
        offersDescriptionEl.classList.remove('padding-bottom');
    }

/*
 * OFFERS DISPLAY
 * Populate contents of the block offer display
 */
    if(offerGroup){
        __tnt.subscription.offers.populate(offerGroup, outputEl, headerEl, templateEl, limit, context, legacyContext, function(t, offer){
            // Callback actions on each template item
            var descriptionEl = t.querySelector('.method-description'),
                descriptionElH4 = null;

            if(descriptionEl){
                descriptionElH4 = descriptionEl.querySelector('h4');

                if(descriptionElH4){
                    descriptionElH4.addEventListener('click',function(ev){
                        ev.target.parentNode.classList.toggle('hide-mobile');
                    });
                }
            }

            t.querySelector('a.method-link').addEventListener('click',function(ev){
                ev.preventDefault();

                var purchaseForm = document.getElementById('form-super-purchase');

                if(ev.target){
                    // Get product data from target element
                    var productData = JSON.parse(ev.target.getAttribute('data-product'));
                }

                if(ev.target.getAttribute('data-subscription-service')){
                    var serviceId = ev.target.getAttribute('data-subscription-service'),
                        serviceName = ev.target.getAttribute('data-name'),
                        rateId = ev.target.getAttribute('data-subscription-rate'),
                        rateEl = document.getElementById('user-purchase-rate'),
                        rateName = ev.target.getAttribute('data-subscription-rate-name');

                    __tnt.subscription.offers.purchaseDetails(offer, siteName, serviceName, serviceId, rateId, rateEl, rateName, purchaseTab, purchaseForm, previewMode, productData).then(function(){
                        var modalIsOpen = true;
                        if(modalEl && modalEl.classList.contains('in')){ modalIsOpen = false; }
                        __tnt.subscription.access.showTab(purchaseTab, "access-offers-modal", "access-methods", modalIsOpen);
                        $(modalEl).modal('show');
                        purchaseForm.dispatchEvent(tntPurchase);
                    }).catch(function(e){
                        console.log(e);

                        __tnt.subscription.access.showTab(errorTab, "access-offers-modal", "access-methods", true);
                        $(modalEl).modal('show');
                    });
                }else{
                    if(ev.target.href!="javascript:void(0)"){
                        document.location = ev.target.href;
                    }

                } // end service check
            });
        }).then(function(data){
           var wallBehavior = oResp.access_rule.wall_behavior,
               content = document.getElementById('asset-content'),
               encryptedContent = null,
               subscriberOnly = null,
               inPagePaywall = null,
               dismissButton = null,
               loginLink = null,
               loginModal = document.getElementById('user-modal-tab-login');
               
            if(content){
                inPagePaywall = content.querySelector('.access-offers-in-page');
                encryptedContent = content.querySelector(".subscriber-only.encrypted-content"),
                subscriberOnly = content.querySelectorAll(".subscriber-only,.subscriber-hide");
               
                if(inPagePaywall){
                    // Handle any login status dependent elements within the in-page paywall
                    var loggedIn = __tnt.user.loggedIn,
                        liEl = inPagePaywall.querySelectorAll('.logged-in'),
                        nlEl = inPagePaywall.querySelectorAll('.not-logged-in'),
                        elShow = nlEl,
                        elRemove = liEl,
                        classRemove = 'not-logged-in';

                    if(loggedIn){ elShow = liEl; elRemove = nlEl; classRemove = 'logged-in' }
                    for(i=0; i< elShow.length; i++){
                        elShow[i].classList.remove(classRemove);
                    }
                    for(j=0; j< elRemove.length; j++){
                        elRemove[j].remove();
                    }

                    // set the dismiss button if it exists
                    dismissButton = inPagePaywall.querySelector('button.close');

                    // set the login link if it exists
                    loginLink = inPagePaywall.querySelector('.access-login .login-toggle');
                    
                    if(loginLink && loginModal){
                        loginLink.addEventListener('click',function(ev){

                            __tnt.subscription.access.showTab(loginModal, "access-offers-modal", "access-methods", true);
                            $(modalEl).modal('show');
                        });
                        
                    // prevent the login link from displaying if there is nothing for it to open
                    } else if(loginLink){
                        loginLink.remove();
                    }
               }

               if(subscriberOnly && !encryptedContent && inPagePaywall && wallBehavior == "nag"){
                    if(dismissButton){
                        dismissButton.addEventListener('click',function(ev){
                            inPagePaywall.hidden = true;

                            var articleBody = document.getElementById('article-body'),
                                subscriberOnly = null;

                            if(articleBody){
                                subscriberOnly = articleBody.querySelectorAll('.subscriber-only');

                                if(subscriberOnly){
                                    subscriberOnly.forEach(function(el){
                                        el.style = "";
                                        el.hidden = false;
                                    });
                                }
                            }
                        });
                        dismissButton.classList.add('tnt-clickable');
                    }
                }
                
                if(dismissButton && !dismissButton.classList.contains('tnt-clickable')){ dismissButton.remove() }
           }

            if(!previewMode){
                var detail = {};

                if(oResp && oResp.access_rule){
                    detail.rule_id = oResp.access_rule.id;
                    detail.rule_name = oResp.access_rule.name;
                }

                __tnt.subscription.offers.logImpression(data, detail);
            }

            if (data.length > 2) {
                modalWrapperEl.classList.remove('modal-small');
            } else if(data.length <= 2) {
                modalWrapperEl.classList.add('modal-small');
            }
        });

    }

    if(inPageOffersEl && articleBodyEl){
        var subscriberOnlyEl = articleBodyEl.querySelector('.subscriber-only'),
            subscriberParentEl = null;

        if(subscriberOnlyEl){
            subscriberParentEl = subscriberOnlyEl.parentNode;
        }

        if(subscriberOnlyEl && subscriberParentEl){
            subscriberParentEl.insertBefore(inPageOffersEl, subscriberOnlyEl);
        } else if(articleBodyEl){
            articleBodyEl.appendChild(inPageOffersEl);
        }
    }
});

/**
 * Access Denied - Log display of wall, and set displayed rule data
 */
window.__tnt.subscription.d.push(function(oResp){
    var accessRuleId = null;

    if(oResp.access_rule){
        __tnt.trackEvent({'category':'subscription','action':'wall','label':oResp.access_rule.name,'value':0});

        try{
            if(window.localStorage){
                accessRuleId = window.localStorage.getItem('tnt:access:rule');
                
                if(oResp.access_rule.id != accessRuleId){
                    localStorage.setItem('tnt:access:rule', oResp.access_rule.id);
                    localStorage.setItem('tnt:access:rule:name', oResp.access_rule.name);
                    __tnt.trackEvent({'category':'Access Control','action':'Access Rule Change','label':oResp.access_rule.name,'value':''});
                }

            }
        }catch(e){}
    }
});

__tnt.subscription.access.manifest.denied.resolve();
